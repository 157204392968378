<template>
  <div>
    <el-tabs class="tabs" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="需求列表" name="first"> <demandList v-if="activeName == 'first'" /> </el-tab-pane>
      <el-tab-pane label="草稿箱" name="second"> <drafts v-if="activeName == 'second'" /> </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import demandList from "./components/demandList";
import drafts from "./components/drafts";
export default {
  data() {
    return {
      activeName: "first",
    };
  },
  components: {
    demandList,
    drafts
  },
  methods: {
    handleClick(tab) {
      const { name } = tab;
      this.activeName = name;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>